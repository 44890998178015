<template>
  <div>
    <Toolbar :handle-reset="resetForm" :handle-submit="onSendForm"></Toolbar>
    <OfferForm :errors="violations" :values="item" ref="createForm" :handle-message="showMessage"/>
    <Loading :visible="isLoading"/>
  </div>
</template>

<script>
import {mapActions} from 'vuex';
import {createHelpers} from 'vuex-map-fields';
import OfferForm from '../../components/offer/Form';
import Loading from '../../components/Loading';
import Toolbar from '../../components/Toolbar';
import CreateMixin from '../../mixins/CreateMixin';

const servicePrefix = 'Offer';

const {mapFields} = createHelpers({
  getterType: 'offer/getField',
  mutationType: 'offer/updateField'
});

export default {
  name: 'OfferCreate',
  servicePrefix,
  mixins: [CreateMixin],
  components: {
    Loading,
    Toolbar,
    OfferForm
  },
  data() {
    return {
      item: {
        positions: []
      }
    };
  },
  computed: {
    ...mapFields(['error', 'isLoading', 'created', 'violations'])
  },
  methods: {
    ...mapActions('offer', ['create', 'reset']),
    getItemSubs() {
      return {
        profile: {}
      }
    }
  }
};
</script>
